<template>
    <div>
        <!-- MarkerCluster -->
        <b-row>
            <b-col md="12">
                <b-card class="card-congratulations bg-warning match-height">
                    <b-row>
                        <b-col md="4">
                            <rekap :kabupaten=kabupaten @loadData=loadData @loadDataAwal=loadDataAwal ></rekap>
                        </b-col>
                        <b-col md="8"> 
                            <rekap_detail :kategori=kategori @LoadDatakategori="LoadDatakategori"></rekap_detail>

                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

        </b-row>

        <b-row class="match-height">
            <!-- <b-col md="4"> 
                <rekap :kabupaten=kabupaten @loadData=loadData @loadDataAwal = loadDataAwal  ></rekap>
                <b-card class="card-congratulations bg-warning match-height"> 
                    <rekap_detail :kategori=kategori @LoadDatakategori="LoadDatakategori"></rekap_detail>
                </b-card> 
            </b-col> -->
            <b-col md="12">
                <b-card> 
                    <maps :rs = rs :dataWilayah = dataWilayah @dataMap =dataMap></maps>
                </b-card>
            </b-col>
        </b-row>

        <detail_bpj :rsDetail = rsDetail></detail_bpj>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import detail_bpj from './component/detail_bpj.vue';
    import rekap from './component/rekap.vue';
    import table_data from './component/tabel.vue';
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'
    import Statistik from './component/statistik.vue';
    import Rekap_detail from './component/rekap_detail.vue';
    import maps from './component/map.vue'; 
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            rekap,
            table_data,
            Statistik,
            Rekap_detail,
            detail_bpj,
            maps
        },
        mixins: [Base],
        data() {
            return {
                kategori: [],
                rs: [],
                rs_laka:[],
                dataWilayah : {},
                kabupaten: [],
                id_kategori: "",
                id_wilayah: "",
                rsDetail : {}
            }
        },
        mounted() {
            this.cek_token();
            this.load_data();
            this.get_kabupaten();
            this.kategori_titik_rawan() 
        },
        methods: {
            loadDataAwal(){
                this.id_kategori = "";
                this.id_wilayah =  "";
                this.load_data();
                this.kategori_titik_rawan()
            },
            dataMap(value){
                this.rsDetail = value
            },
            loadData(value) { 
                this.id_wilayah = value.id_wilayah;
                this.dataWilayah = value;
                this.load_data();
                this.kategori_titik_rawan()

            },
            LoadDatakategori(value){
                if(this.id_kategori =='0'){
                    this.id_wilayah = '';  
                    this.id_kategori ='';
                    this.load_data();
                this.kategori_titik_rawan()
                }
                this.id_kategori = value.id_kategori,
                this.load_data();
                this.kategori_titik_rawan()
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/gis/load_data',
                        data: {
                            id_kategori: self.id_kategori,
                            id_wilayah: self.id_wilayah
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
           
            async get_kabupaten() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/gis/kabupaten',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kabupaten = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async kategori_titik_rawan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/gis/kategori_titik_rawan',
                        data: {
                            id_wilayah: self.id_wilayah
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kategori = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>

<style>

</style>