<template>
    <div>
        <l-map :zoom="zoom" :center="center">
            <l-tile-layer :url="url" />
            <!-- <div v-for="mapMarker in rs" v-bind:key=mapMarker>
                <l-marker :lat-lng="mapMarker.kordinat" @click="loadData(mapMarker)">
                    <l-popup v-b-modal.modal-1>{{ mapMarker.id }}</l-popup>
                    <l-icon >
                        <b-img :src="mapMarker.marker" height="20" />
                    </l-icon>
                </l-marker>
            </div> -->

            <v-marker-cluster>
                <div v-for="mapMarker in rs" v-bind:key=mapMarker.id>
                    <l-marker :lat-lng="mapMarker.kordinat"   @click="dataMap(mapMarker)" v-b-modal.modal-2>
                        <l-popup v-b-modal.modal-2>ddddddddddd</l-popup>
                        <l-icon >
                            <b-img :src="mapMarker.marker" height="25" />
                        </l-icon>
                    </l-marker>
                </div>
            </v-marker-cluster> 

            <!-- <l-geo-json :geojson="jateng"  :options="options"  /> -->
            <l-geo-json :geojson="geojson" :options="options" />
            <l-geo-json :geojson="geojson_nasional" :options="customOptions" />
            <l-geo-json :geojson="geojson_kapupaten" :options="kapupatenOption" />
        </l-map>
    </div>
</template>

<script>
    import 'leaflet.markercluster/dist/MarkerCluster.css';
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
    /* eslint-disable global-require */
    import {
        BImg,
    } from 'bootstrap-vue'

    import {
        LIcon,
        LMap,
        LTileLayer,
        LMarker,
        LCircle,
        LPolygon,
        LGeoJson
    } from 'vue2-leaflet'

    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
    import {
        Icon
    } from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    // eslint-disable-next-line no-underscore-dangle
    delete Icon.Default.prototype._getIconUrl
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })
    /* eslint-disable global-require */

    export default {
        components: {
            LIcon,
            LMap,
            LTileLayer,
            LMarker,
            LCircle,
            LPolygon,
            LGeoJson,
            BImg,
            'v-marker-cluster': Vue2LeafletMarkerCluster
        },
        props: {
            rs: {

            },
            rs_laka: {

            }
        },
        data() {
            return {
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 9,
                center: [-7.338788978188758, 109.99955636327921],
                markerLatLng: [-7.73530100000, 110.09286700000],
                geojson: null,
                geojson_nasional: null,
                jateng: null,
                geojson_kapupaten: null,
                customOptions: {
                    style: {
                        color: '#ffaf03', // Ganti warna garis menjadi merah
                        fillColor: 'blue', // Ganti warna isi menjadi biru
                        weight: 4, // Ganti lebar garis
                        fillOpacity: 0.5 // Ganti opasitas isi
                    }
                },
                options: {
                    style: {
                        color: '#1826d6', // Ganti warna garis menjadi merah
                        fillColor: 'blue', // Ganti warna isi menjadi biru
                        weight: 1.5, // Ganti lebar garis
                        fillOpacity: 0.5 // Ganti opasitas isi
                    }
                },
                kapupatenOption: {
                    style: {
                        color: 'blue', // Ganti warna garis menjadi merah
                        fillColor: 'yellow', // Ganti warna isi menjadi biru
                        weight: 0.1, // Ganti lebar garis
                        fillOpacity: 0.1 // Ganti opasitas isi
                    }
                }
            }
        },
        emits:['dataMap'],
        methods: {
            dataMap(value){ 
                this.$emit("dataMap", value)
                this.$root.$emit('bv::show::modal', 'modal-2')
            },
            modal() {
                this.$root.$emit('bv::show::modal', 'modal-2')
            }
        },
        async created() {
            const response = await fetch('https://fllaj-jatengprov.wbsantosa.com/jalan_provinsi.geojson')
            this.geojson = await response.json()

            const response_nasional = await fetch('https://fllaj-jatengprov.wbsantosa.com/jalan_nasional.geojson')
            this.geojson_nasional = await response_nasional.json()
            const response_jateng = await fetch('https://fllaj-jatengprov.wbsantosa.com/jateng.geojson')
            this.jateng = await response_jateng.json()

            const response_kapupaten = await fetch('https://fllaj-jatengprov.wbsantosa.com/kabupaten.geojson')
            this.geojson_kapupaten = await response_kapupaten.json()

            
            // const response = await fetch('http://localhost:8080/jalan_provinsi.geojson')
            // this.geojson = await response.json()

            // const response_nasional = await fetch('http://localhost:8080/jalan_nasional.geojson')
            // this.geojson_nasional = await response_nasional.json()
            // const response_jateng = await fetch('http://localhost:8080/jateng.geojson')
            // this.jateng = await response_jateng.json()

            // const response_kapupaten = await fetch('http://localhost:8080/kabupaten.geojson')
            // this.geojson_kapupaten = await response_kapupaten.json()
        },
    }
</script>

<style lang="scss">
    @import "~leaflet.markercluster/dist/MarkerCluster.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

    .vue2leaflet-map {
        &.leaflet-container {
            height: 560px;
        }
    }
</style>