<template>
    <div>
        <b-row>
            <b-col md="12">
               
                    <b-row  >
                        <b-col md="9"> 
                            <b-form-group label="Kabupaten">
                                <v-select v-model="id_wilayah" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" @input="loadData()"
                                    label="nm_wil" :options="kabupaten" />
                            </b-form-group> 
                        </b-col>
                        <b-col md="3">
                           <a href="#"  @click="loadDataAwal()" >
                            <b-avatar rounded="" size="50" variant="primary" class="mt-1" ><feather-icon icon ="RefreshCcwIcon" size="20" ></feather-icon></b-avatar>
                           </a>
                       </b-col>
                    </b-row>  
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormGroup, BAvatar
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            BFormGroup,
            vSelect,BAvatar,
                FeatherIcon

        },
        props:{
            kabupaten:{},
          
        },
        data() {
            return { 
                id_wilayah :{}
            }
        },
        emits:["loadData", "loadDataAwal"],
        methods:{
            loadDataAwal(){
                this.$emit('loadDataAwal');
                this.id_wilayah ={}
            },
            loadData( ){
                
                this.$emit('loadData', this.id_wilayah)
            }
        }

    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>