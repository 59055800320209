
<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1"> 
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data"> 
                    <b-row class="mlm-5 ">
                        <b-col md="4">
                            <h6><small>Jenis Rawan</small></h6>
                            <h1 class="text-warning"><strong>LAKA</strong></h1>
                            <h6><small><strong> <feather-icon icon="MapPinIcon" size="15" /> -6.9929587,110.4873544</strong></small></h6>
                             
                            <b-button v-b-modal.modal-2 block variant="primary" size="sm" ><feather-icon icon="CheckCircleIcon" size="15"  /> Detail Lokasi</b-button> 
                           
                        </b-col>
                        <b-col md="8">
                            <h6 ><small><strong>Keterangan</strong> {{ data.item.id }}</small></h6> 
                            <p style="text-align:justify"><small>Mohon bantuanya untuk lampu sepanjang jalan di Jembatan Sigug, Sigug, Bumiharjo, Kec. Borobudur,
                                 Kabupaten Magelang, Jawa Tengah Mati sudah satu minggu</small>
                                 <br><i><small>**surveyor Kismardha Raffi </small></i> 
                                </p>
                            
                        </b-col> 
                         
                    </b-row>
                </template> 
            </b-table>
        </b-col>

        <b-col cols="6">
          Total Row  {{ totalRows }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 2,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [], 
                fields: [ 
                    {
          key: 'aduan',
          label: '',
          thStyle: {
            display: 'none'
          }
        }
                ],
                items: [{
                        id: 1,
                         
                    },
                    {
                        id: 2,
                    
                    },
                    {
                        id: 3, 
                    },
                    {
                        id: 4, 
                    },
                    
                ],
                 
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {  
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>